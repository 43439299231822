// Countdown.js
import { useEffect, useState } from 'react';
import '../css/Countdown.css'; 

const Countdown = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / (1000 * 60)) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  const format = (val) => String(val).padStart(2, '0');

  return (
    <div className="countdown-wrapper">
      <div className="countdown-timer">
        {format(timeLeft.days)}d : {format(timeLeft.hours)}h : {format(timeLeft.minutes)}m : {format(timeLeft.seconds)}s
      </div>
      <div className="countdown-date">
        Until {new Date(targetDate).toLocaleString()}
      </div>
    </div>
  );
};

export default Countdown;
